import React, { useState, useEffect } from "react";

const App = () => {
  const [step, setStep] = useState(1);
  const [tournamentName, setTournamentName] = useState("");
  const [participantCount, setParticipantCount] = useState("");
  const [participants, setParticipants] = useState([]);
  const [fixtures, setFixtures] = useState([]);
  const [leagueTable, setLeagueTable] = useState([]);
  const [matchType, setMatchType] = useState("");
  const [tempScores, setTempScores] = useState({});
  const [champion, setChampion] = useState(null);
  const [selectedSystem, setSelectedSystem] = useState(null);
  const [challengeStep, setChallengeStep] = useState(1);
  const [gladiator1, setGladiator1] = useState("");
  const [gladiator2, setGladiator2] = useState("");
  const [bestOf, setBestOf] = useState("");
  const [challengeFixtures, setChallengeFixtures] = useState([]);
  const [challengeLeagueTable, setChallengeLeagueTable] = useState([]);
  const [winner, setWinner] = useState(null);
  const [eliminationParticipants, setEliminationParticipants] = useState([]);
  const [eliminationFixtures, setEliminationFixtures] = useState([]);
  const [eliminationRound, setEliminationRound] = useState(1);
  const [eliminationWinner, setEliminationWinner] = useState(null);
  const [groupParticipants, setGroupParticipants] = useState([]);
  const [groupCount, setGroupCount] = useState("");
  const [formattedGroups, setFormattedGroups] = useState([]);
  const [matchCount, setMatchCount] = useState("");
  const [groupFixtures, setGroupFixtures] = useState({});
  const [groupStandings, setGroupStandings] = useState({});
  const [selectedGroup, setSelectedGroup] = useState(0); // Başlangıçta ilk grup seçili
  const [groupsCompleted, setGroupsCompleted] = useState(
    Array(formattedGroups.length).fill(false)
  );
  const [knockoutFixtures, setKnockoutFixtures] = useState([]);
  const [teamsAdvancing, setTeamsAdvancing] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    const savedState = localStorage.getItem("tournamentState");
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      setStep(parsedState.step);
      setTournamentName(parsedState.tournamentName);
      setParticipantCount(parsedState.participantCount);
      setParticipants(parsedState.participants);
      setFixtures(parsedState.fixtures);
      setLeagueTable(parsedState.leagueTable);
      setMatchType(parsedState.matchType);
      setTempScores(parsedState.tempScores);
      setChampion(parsedState.champion);
    }
  }, []);
  useEffect(() => {
    if (step === 13) {
      const newFixtures = {};
      formattedGroups.forEach((group, index) => {
        newFixtures[index] = generateGroupFixtures(group, matchCount);
      });
      setGroupFixtures(newFixtures);

      const newStandings = {};
      formattedGroups.forEach((group, index) => {
        newStandings[index] = group.map((team) => ({
          name: team.name,
          played: 0,
          won: 0,
          drawn: 0,
          lost: 0,
          goalsFor: 0,
          goalsAgainst: 0,
          goalDifference: 0,
          points: 0,
        }));
      });
      setGroupStandings(newStandings);
    }
  }, [step, formattedGroups, matchCount]);
  useEffect(() => {
    const stateToSave = {
      step,
      tournamentName,
      participantCount,
      participants,
      fixtures,
      leagueTable,
      matchType,
      tempScores,
      champion,
    };
    localStorage.setItem("tournamentState", JSON.stringify(stateToSave));
  }, [
    step,
    tournamentName,
    participantCount,
    participants,
    fixtures,
    leagueTable,
    matchType,
    tempScores,
    champion,
  ]);
  const handleGroupParticipantChange = (index, field, value) => {
    const updatedParticipants = [...groupParticipants];
    updatedParticipants[index] = {
      ...updatedParticipants[index],
      [field]: value,
    };
    setGroupParticipants(updatedParticipants);
  };
  const handleGroupsSubmit = () => {
    const seededParticipants = groupParticipants.filter((p) => p.isSeeded);
    const unseededParticipants = groupParticipants.filter((p) => !p.isSeeded);

    // Seri başı olanları ve olmayanları karıştır
    const shuffledSeeded = seededParticipants.sort(() => 0.5 - Math.random());
    const shuffledUnseeded = unseededParticipants.sort(
      () => 0.5 - Math.random()
    );

    const groups = Array.from({ length: parseInt(groupCount) }, () => []);

    // Seri başı olanları gruplara dağıt
    shuffledSeeded.forEach((participant, index) => {
      groups[index % groups.length].push(participant);
    });

    // Seri başı olmayanları gruplara dağıt
    shuffledUnseeded.forEach((participant, index) => {
      groups[index % groups.length].push(participant);
    });

    setFormattedGroups(groups);
    setStep(12); // Yeni adım için
  };
  const handleSystemSelect = (system) => {
    setSelectedSystem(system);
    if (system === "challenge") {
      setChallengeStep(1);
      setStep(7);
    } else if (system === "elimination") {
      setStep(8);
    } else if (system === "groups") {
      setStep(11); // Yeni adım için
    } else if (system === "league") {
      setStep(2); // Eski "Gruplar" (şimdi "Lig") için
    }
  };

  const handleTournamentNameSubmit = () => {
    if (tournamentName.trim() !== "") {
      setStep(3);
    }
  };

  const handleParticipantCountSubmit = () => {
    if (participantCount > 1) {
      setStep(4);
      setParticipants(Array(parseInt(participantCount)).fill(""));
    }
  };

  const handleParticipantNameChange = (index, name) => {
    const newParticipants = [...participants];
    newParticipants[index] = name;
    setParticipants(newParticipants);
  };

  const handleParticipantsSubmit = () => {
    if (participants.every((name) => name.trim() !== "")) {
      setStep(5);
    }
  };

  const handleMatchTypeSubmit = () => {
    if (matchType) {
      setStep(6);
      generateFixtures();
      initializeLeagueTable();
    }
  };

  const handleEliminationParticipantCountSubmit = () => {
    if ([4, 8, 16, 32, 64].includes(Number(participantCount))) {
      setStep(9);
      setEliminationParticipants(
        Array(Number(participantCount)).fill({ name: "", isSeeded: false })
      );
    }
  };
  const handleEliminationParticipantNameChange = (index, name, isSeeded) => {
    const newParticipants = [...eliminationParticipants];
    newParticipants[index] = { name, isSeeded };
    setEliminationParticipants(newParticipants);
  };

  const getRoundName = (remainingTeams) => {
    switch (remainingTeams) {
      case 8:
        return "Çeyrek Final";
      case 4:
        return "Yarı Final";
      case 2:
        return "Final";
      default:
        return "1. Tur";
    }
  };

  const endTournament = () => {
    setShowConfirmation(true);
  };

  const confirmEndTournament = () => {
    localStorage.removeItem("tournamentState");
    setStep(1);
    setTournamentName("");
    setParticipantCount("");
    setParticipants([]);
    setFixtures([]);
    setLeagueTable([]);
    setMatchType("");
    setTempScores({});
    setChampion(null);
    setGroupsCompleted(Array(formattedGroups.length).fill(false));
    setKnockoutFixtures([]);
    setShowConfirmation(false);
  };

  const updateKnockoutScore = (matchIndex, team, score) => {
    const newFixtures = [...knockoutFixtures];
    newFixtures[matchIndex][team === "home" ? "homeScore" : "awayScore"] =
      parseInt(score);
    setKnockoutFixtures(newFixtures);
  };

  const confirmKnockoutScore = (matchIndex) => {
    const newFixtures = [...knockoutFixtures];
    const match = newFixtures[matchIndex];
    if (
      match.homeScore !== null &&
      match.awayScore !== null &&
      match.homeScore !== match.awayScore
    ) {
      match.isConfirmed = true;
      setKnockoutFixtures(newFixtures);

      const allMatchesConfirmed = newFixtures.every(
        (match) => match.isConfirmed
      );
      if (allMatchesConfirmed) {
        createNextRoundFixtures(newFixtures);
      }
    }
  };

  const createNextRoundFixtures = (previousRoundFixtures) => {
    const advancingTeams = previousRoundFixtures.map((match) =>
      match.homeScore > match.awayScore ? match.home : match.away
    );

    if (advancingTeams.length === 1) {
      const champion = advancingTeams[0];
      setChampion(champion.name);
      return;
    }

    const nextRoundFixtures = [];
    for (let i = 0; i < advancingTeams.length; i += 2) {
      nextRoundFixtures.push({
        home: advancingTeams[i],
        away: advancingTeams[i + 1],
        homeScore: null,
        awayScore: null,
      });
    }

    setKnockoutFixtures(nextRoundFixtures);
    setStep(14); // Bir sonraki adım
  };

  const editKnockoutScore = (matchIndex) => {
    const newFixtures = [...knockoutFixtures];
    const match = newFixtures[matchIndex];
    match.homeScore = null;
    match.awayScore = null;
    match.isConfirmed = false;
    setKnockoutFixtures(newFixtures);
  };

  const finishGroup = (groupIndex) => {
    const newGroupsCompleted = [...groupsCompleted];
    newGroupsCompleted[groupIndex] = true;
    setGroupsCompleted(newGroupsCompleted);

    if (newGroupsCompleted.every((completed) => completed)) {
      createKnockoutFixtures();
    }
  };
  const createKnockoutFixtures = () => {
    const advancingTeams = [];

    for (let i = 0; i < Object.keys(groupStandings).length; i++) {
      const groupStanding = groupStandings[i];
      for (let j = 0; j < teamsAdvancing; j++) {
        advancingTeams.push({ ...groupStanding[j], groupIndex: i });
      }
    }

    // Eşleşmeleri belirle
    const knockoutFixtures = [];
    const numGroups = Object.keys(groupStandings).length;
    const half = advancingTeams.length / 2;

    for (let i = 0; i < half; i++) {
      let home, away;
      if (teamsAdvancing === 2) {
        // Gruptan 2 takım çıkıyorsa, A1-B2, A2-B1 şeklinde eşleştir
        const groupIndex = i % numGroups;
        const nextGroupIndex = (groupIndex + 1) % numGroups;
        home = advancingTeams.find(
          (team) =>
            team.groupIndex === groupIndex &&
            team === groupStandings[groupIndex][0]
        );
        away = advancingTeams.find(
          (team) =>
            team.groupIndex === nextGroupIndex &&
            team === groupStandings[nextGroupIndex][1]
        );
      } else {
        // Diğer durumlar için mevcut mantığı kullan
        home = advancingTeams[i];
        away = advancingTeams[advancingTeams.length - 1 - i];
      }
      knockoutFixtures.push({
        home: home,
        away: away,
        homeScore: null,
        awayScore: null,
      });
    }

    setKnockoutFixtures(knockoutFixtures);
    setStep(14);
  };

  const startEliminationTournament = () => {
    if (eliminationParticipants.every((p) => p.name.trim() !== "")) {
      setStep(10);
      generateEliminationFixtures();
    }
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const updateGroupScore = (groupIndex, weekIndex, matchIndex, team, score) => {
    const newFixtures = { ...groupFixtures };
    newFixtures[groupIndex][weekIndex].matches[matchIndex][
      team === "home" ? "homeScore" : "awayScore"
    ] = parseInt(score);
    setGroupFixtures(newFixtures);

    // Skor güncellendiğinde grubu sonlandır butonunu yeniden aktif yap
    const newGroupsCompleted = [...groupsCompleted];
    newGroupsCompleted[groupIndex] = false;
    setGroupsCompleted(newGroupsCompleted);
  };

  const confirmGroupScore = (groupIndex, weekIndex, matchIndex) => {
    const newFixtures = { ...groupFixtures };
    const match = newFixtures[groupIndex][weekIndex].matches[matchIndex];
    if (match.homeScore !== null && match.awayScore !== null) {
      match.isConfirmed = true;
      setGroupFixtures(newFixtures);
      updateGroupStandings(groupIndex);

      // Tüm skorlar onaylanınca yeni eşleşmeleri oluştur
      const allScoresConfirmed = Object.values(groupFixtures)
        .flat()
        .every((week) => week.matches.every((match) => match.isConfirmed));

      if (allScoresConfirmed) {
        createKnockoutFixtures();
      }
    }
  };

  const editGroupScore = (groupIndex, weekIndex, matchIndex) => {
    const newFixtures = { ...groupFixtures };
    const match = newFixtures[groupIndex][weekIndex].matches[matchIndex];
    match.homeScore = null;
    match.awayScore = null;
    match.isConfirmed = false;
    setGroupFixtures(newFixtures);
    updateGroupStandings(groupIndex);

    // Skor iptal edildiğinde grubu sonlandır butonunu yeniden aktif yap
    const newGroupsCompleted = [...groupsCompleted];
    newGroupsCompleted[groupIndex] = false;
    setGroupsCompleted(newGroupsCompleted);
  };

  const updateGroupStandings = (groupIndex) => {
    const newStandings = formattedGroups[groupIndex].map((team) => ({
      name: team.name,
      played: 0,
      won: 0,
      drawn: 0,
      lost: 0,
      goalsFor: 0,
      goalsAgainst: 0,
      goalDifference: 0,
      points: 0,
    }));

    groupFixtures[groupIndex].forEach((week) => {
      week.matches.forEach((match) => {
        if (match.isConfirmed) {
          const homeTeam = newStandings.find(
            (team) => team.name === match.home.name
          );
          const awayTeam = newStandings.find(
            (team) => team.name === match.away.name
          );

          if (homeTeam && awayTeam) {
            homeTeam.played += 1;
            awayTeam.played += 1;

            homeTeam.goalsFor += match.homeScore;
            homeTeam.goalsAgainst += match.awayScore;
            awayTeam.goalsFor += match.awayScore;
            awayTeam.goalsAgainst += match.homeScore;

            if (match.homeScore > match.awayScore) {
              homeTeam.won += 1;
              awayTeam.lost += 1;
              homeTeam.points += 3;
            } else if (match.homeScore < match.awayScore) {
              awayTeam.won += 1;
              homeTeam.lost += 1;
              awayTeam.points += 3;
            } else {
              homeTeam.drawn += 1;
              awayTeam.drawn += 1;
              homeTeam.points += 1;
              awayTeam.points += 1;
            }

            homeTeam.goalDifference = homeTeam.goalsFor - homeTeam.goalsAgainst;
            awayTeam.goalDifference = awayTeam.goalsFor - awayTeam.goalsAgainst;
          }
        }
      });
    });

    newStandings.sort(
      (a, b) => b.points - a.points || b.goalDifference - a.goalDifference
    );
    setGroupStandings((prevStandings) => ({
      ...prevStandings,
      [groupIndex]: newStandings,
    }));
  };

  const generateGroupFixtures = (group, matchCount) => {
    const teams = [...group];
    let fixtures = [];

    if (teams.length % 2 !== 0) {
      teams.push({ name: "Bye" });
    }

    const totalRounds = teams.length - 1;
    const halfSize = teams.length / 2;

    const homeCount = {};
    const awayCount = {};

    teams.forEach((team) => {
      homeCount[team.name] = 0;
      awayCount[team.name] = 0;
    });

    for (let round = 0; round < totalRounds; round++) {
      let weekFixtures = [];
      for (let i = 0; i < halfSize; i++) {
        let home = teams[i];
        let away = teams[teams.length - 1 - i];
        if (home.name !== "Bye" && away.name !== "Bye") {
          if (homeCount[home.name] > awayCount[home.name]) {
            [home, away] = [away, home];
          }
          weekFixtures.push({
            home: home,
            away: away,
            homeScore: null,
            awayScore: null,
          });
          homeCount[home.name]++;
          awayCount[away.name]++;
        }
      }
      fixtures.push({ week: round + 1, matches: weekFixtures });

      const lastTeam = teams.pop();
      teams.splice(1, 0, lastTeam);
    }

    if (matchCount === "double") {
      const secondHalfFixtures = fixtures.map((round) => ({
        week: round.week + totalRounds,
        matches: round.matches.map((match) => ({
          home: match.away,
          away: match.home,
          homeScore: null,
          awayScore: null,
        })),
      }));
      fixtures = [...fixtures, ...secondHalfFixtures];
    }

    return fixtures;
  };

  const generateEliminationFixtures = () => {
    const shuffled = [...eliminationParticipants].sort(
      () => Math.random() - 0.5
    );
    const seeded = shuffled.filter((p) => p.isSeeded);
    const unseeded = shuffled.filter((p) => !p.isSeeded);

    let fixtures = [];
    for (let i = 0; i < shuffled.length / 2; i++) {
      if (seeded.length > 0 && unseeded.length > 0) {
        fixtures.push({
          home: seeded.pop(),
          away: unseeded.pop(),
          homeScore: null,
          awayScore: null,
        });
      } else {
        const home = seeded.pop() || unseeded.pop();
        const away = seeded.pop() || unseeded.pop();
        fixtures.push({ home, away, homeScore: null, awayScore: null });
      }
    }

    setEliminationFixtures(fixtures);
    setEliminationRound(Math.log2(shuffled.length));
  };
  const updateEliminationScore = (index, team, score) => {
    const newFixtures = [...eliminationFixtures];
    if (team === "home") {
      newFixtures[index].homeScore = score;
    } else {
      newFixtures[index].awayScore = score;
    }
    setEliminationFixtures(newFixtures);
  };
  const editEliminationScore = (index) => {
    const newFixtures = [...eliminationFixtures];
    const match = newFixtures[index];

    if (match.isCompleted) {
      match.homeScore = null;
      match.awayScore = null;
      match.isCompleted = false;
      setEliminationFixtures(newFixtures);
    }
  };
  const confirmEliminationScore = (index) => {
    const newFixtures = [...eliminationFixtures];
    const match = newFixtures[index];

    if (
      match.homeScore !== null &&
      match.awayScore !== null &&
      match.homeScore !== match.awayScore
    ) {
      match.isCompleted = true;
      setEliminationFixtures(newFixtures);

      if (eliminationFixtures.every((match) => match.isCompleted)) {
        advanceEliminationRound();
      }
    }
  };

  const advanceEliminationRound = () => {
    const winners = eliminationFixtures.map((match) =>
      match.homeScore > match.awayScore ? match.home : match.away
    );

    if (winners.length === 1) {
      setEliminationWinner(winners[0]);
    } else {
      const newFixtures = [];
      for (let i = 0; i < winners.length; i += 2) {
        newFixtures.push({
          home: winners[i],
          away: winners[i + 1],
          homeScore: null,
          awayScore: null,
        });
      }
      setEliminationFixtures(newFixtures);
      setEliminationRound((prevRound) => prevRound - 1);
    }
  };
  const generateFixtures = () => {
    const teams = [...participants];
    let fixtures = [];

    if (teams.length % 2 !== 0) {
      teams.push("Bye");
    }

    const totalRounds = teams.length - 1;
    const halfSize = teams.length / 2;

    const homeCount = {};
    const awayCount = {};

    teams.forEach((team) => {
      homeCount[team] = 0;
      awayCount[team] = 0;
    });

    for (let round = 0; round < totalRounds; round++) {
      let roundFixtures = [];
      for (let i = 0; i < halfSize; i++) {
        let home = teams[i];
        let away = teams[teams.length - 1 - i];
        if (home !== "Bye" && away !== "Bye") {
          if (homeCount[home] > awayCount[home]) {
            [home, away] = [away, home];
          }
          roundFixtures.push({ home, away, homeScore: null, awayScore: null });
          homeCount[home]++;
          awayCount[away]++;
        }
      }
      fixtures.push({ round: round + 1, matches: roundFixtures });

      const lastTeam = teams.pop();
      teams.splice(1, 0, lastTeam);
    }

    if (matchType === "double") {
      const secondHalfFixtures = fixtures.map((round) => ({
        round: round.round + totalRounds,
        matches: round.matches.map((match) => ({
          home: match.away,
          away: match.home,
          homeScore: null,
          awayScore: null,
        })),
      }));
      fixtures = [...fixtures, ...secondHalfFixtures];
    }

    setFixtures(fixtures);
    setTempScores({});
  };

  const generateChallengeFixtures = () => {
    const fixtures = [];
    for (let i = 1; i <= parseInt(bestOf); i++) {
      fixtures.push({
        round: i,
        home: gladiator1,
        away: gladiator2,
        homeScore: null,
        awayScore: null,
        isCompleted: false,
      });
    }
    setChallengeFixtures(fixtures);
    setChallengeLeagueTable([
      { name: gladiator1, won: 0, lost: 0, points: 0 },
      { name: gladiator2, won: 0, lost: 0, points: 0 },
    ]);
  };
  const updateChallengeScore = (index, team, score) => {
    const newFixtures = [...challengeFixtures];
    if (team === "home") {
      newFixtures[index].homeScore = score;
    } else {
      newFixtures[index].awayScore = score;
    }
    setChallengeFixtures(newFixtures);
  };

  const confirmChallengeScore = (index) => {
    const newFixtures = [...challengeFixtures];
    const match = newFixtures[index];

    if (match.homeScore !== null && match.awayScore !== null) {
      match.isCompleted = true;
      setChallengeFixtures(newFixtures);

      const newLeagueTable = [...challengeLeagueTable];
      const homeGladiator = newLeagueTable.find((g) => g.name === match.home);
      const awayGladiator = newLeagueTable.find((g) => g.name === match.away);

      if (match.homeScore > match.awayScore) {
        homeGladiator.won++;
        homeGladiator.points += 3;
        awayGladiator.lost++;
      } else if (match.homeScore < match.awayScore) {
        awayGladiator.won++;
        awayGladiator.points += 3;
        homeGladiator.lost++;
      }

      setChallengeLeagueTable(newLeagueTable);

      const winnerGladiator = newLeagueTable.find(
        (g) => g.won === Math.ceil(parseInt(bestOf) / 2)
      );
      if (winnerGladiator) {
        setWinner(winnerGladiator.name);
      }
    }
  };

  const editChallengeScore = (index) => {
    const newFixtures = [...challengeFixtures];
    const match = newFixtures[index];

    if (match.isCompleted) {
      const newLeagueTable = [...challengeLeagueTable];
      const homeGladiator = newLeagueTable.find((g) => g.name === match.home);
      const awayGladiator = newLeagueTable.find((g) => g.name === match.away);

      if (match.homeScore > match.awayScore) {
        homeGladiator.won--;
        homeGladiator.points -= 3;
        awayGladiator.lost--;
      } else if (match.homeScore < match.awayScore) {
        awayGladiator.won--;
        awayGladiator.points -= 3;
        homeGladiator.lost--;
      }

      match.isCompleted = false;
      match.homeScore = null;
      match.awayScore = null;

      setChallengeFixtures(newFixtures);
      setChallengeLeagueTable(newLeagueTable);
      setWinner(null);
    }
  };
  const initializeLeagueTable = () => {
    const table = participants.map((name) => ({
      name,
      played: 0,
      won: 0,
      drawn: 0,
      lost: 0,
      goalsFor: 0,
      goalsAgainst: 0,
      goalDifference: 0,
      points: 0,
    }));
    setLeagueTable(table);
  };

  const updateTempScore = (roundIndex, matchIndex, homeScore, awayScore) => {
    const key = `$${roundIndex}-$${matchIndex}`;
    setTempScores((prev) => ({
      ...prev,
      [key]: { homeScore, awayScore },
    }));
  };

  const confirmScore = (roundIndex, matchIndex) => {
    const key = `$${roundIndex}-$${matchIndex}`;
    const { homeScore, awayScore } = tempScores[key] || {};

    if (homeScore !== undefined && awayScore !== undefined) {
      const updatedFixtures = [...fixtures];
      const match = updatedFixtures[roundIndex].matches[matchIndex];
      const prevHomeScore = match.homeScore;
      const prevAwayScore = match.awayScore;

      match.homeScore = homeScore;
      match.awayScore = awayScore;
      match.isCompleted = true;
      setFixtures(updatedFixtures);

      updateLeagueTable(
        match.home,
        match.away,
        homeScore,
        awayScore,
        prevHomeScore,
        prevAwayScore
      );

      setTempScores((prev) => {
        const newTempScores = { ...prev };
        delete newTempScores[key];
        return newTempScores;
      });
    }
  };

  const updateLeagueTable = (
    home,
    away,
    homeScore,
    awayScore,
    prevHomeScore,
    prevAwayScore
  ) => {
    const updatedTable = [...leagueTable];
    const homeTeam = updatedTable.find((team) => team.name === home);
    const awayTeam = updatedTable.find((team) => team.name === away);

    if (prevHomeScore !== null && prevAwayScore !== null) {
      homeTeam.played--;
      awayTeam.played--;
      homeTeam.goalsFor -= prevHomeScore;
      homeTeam.goalsAgainst -= prevAwayScore;
      awayTeam.goalsFor -= prevAwayScore;
      awayTeam.goalsAgainst -= prevHomeScore;

      if (prevHomeScore > prevAwayScore) {
        homeTeam.won--;
        homeTeam.points -= 3;
        awayTeam.lost--;
      } else if (prevHomeScore < prevAwayScore) {
        awayTeam.won--;
        awayTeam.points -= 3;
        homeTeam.lost--;
      } else {
        homeTeam.drawn--;
        awayTeam.drawn--;
        homeTeam.points--;
        awayTeam.points--;
      }
    }

    if (homeScore !== null && awayScore !== null) {
      homeTeam.played++;
      awayTeam.played++;
      homeTeam.goalsFor += homeScore;
      homeTeam.goalsAgainst += awayScore;
      awayTeam.goalsFor += awayScore;
      awayTeam.goalsAgainst += homeScore;

      if (homeScore > awayScore) {
        homeTeam.won++;
        homeTeam.points += 3;
        awayTeam.lost++;
      } else if (homeScore < awayScore) {
        awayTeam.won++;
        awayTeam.points += 3;
        homeTeam.lost++;
      } else {
        homeTeam.drawn++;
        awayTeam.drawn++;
        homeTeam.points++;
        awayTeam.points++;
      }
    }

    homeTeam.goalDifference = homeTeam.goalsFor - homeTeam.goalsAgainst;
    awayTeam.goalDifference = awayTeam.goalsFor - awayTeam.goalsAgainst;

    setLeagueTable(
      updatedTable.sort(
        (a, b) =>
          b.points - a.points ||
          b.goalDifference - a.goalDifference ||
          b.goalsFor - a.goalsFor
      )
    );
  };

  const editScore = (roundIndex, matchIndex) => {
    const updatedFixtures = [...fixtures];
    const match = updatedFixtures[roundIndex].matches[matchIndex];

    if (match.isCompleted) {
      updateLeagueTable(
        match.home,
        match.away,
        null,
        null,
        match.homeScore,
        match.awayScore
      );

      match.homeScore = null;
      match.awayScore = null;
      match.isCompleted = false;
      setFixtures(updatedFixtures);

      const key = `$${roundIndex}-$${matchIndex}`;
      setTempScores((prev) => {
        const newTempScores = { ...prev };
        delete newTempScores[key];
        return newTempScores;
      });
    }
  };

  const isAllMatchesCompleted = () => {
    return fixtures.every((round) =>
      round.matches.every(
        (match) =>
          match.away === null ||
          (match.homeScore !== null && match.awayScore !== null)
      )
    );
  };

  useEffect(() => {
    if (isAllMatchesCompleted() && leagueTable.length > 0) {
      setChampion(leagueTable[0].name);
    }
  }, [fixtures, leagueTable]);

  const styles = {
    container: {
      maxWidth: "800px",
      width: "90%",
      margin: "0 auto",
      padding: "20px",
      boxSizing: "border-box",
      backgroundColor: "#f9f9f9",
      borderRadius: "8px",
      boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    },
    input: {
      width: "100%",
      padding: "12px",
      marginBottom: "20px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      fontSize: "16px",
      boxSizing: "border-box",
    },
    button: {
      backgroundColor: "#007BFF",
      border: "none",
      color: "white",
      padding: "12px 20px",
      textAlign: "center",
      textDecoration: "none",
      display: "inline-block",
      fontSize: "16px",
      margin: "4px 2px",
      cursor: "pointer",
      borderRadius: "4px",
      width: "100%",
      boxSizing: "border-box",
      transition: "background-color 0.3s",
    },
    buttonHover: {
      backgroundColor: "#0056b3",
    },
    formGroup: {
      marginBottom: "20px",
    },
    label: {
      marginBottom: "10px",
      fontSize: "18px",
      fontWeight: "bold",
      display: "block",
    },
    header: {
      backgroundColor: "#f2f2f2",
      padding: "12px",
      marginBottom: "20px",
      borderRadius: "4px",
      textAlign: "center",
      fontSize: "24px",
      fontWeight: "bold",
    },
    optionContainer: {
      display: "flex",
      justifyContent: "space-around",
      marginBottom: "20px",
    },
    optionBox: {
      flex: "1",
      padding: "20px",
      margin: "5px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      textAlign: "center",
      cursor: "pointer",
      transition: "background-color 0.3s",
      userSelect: "none",
    },
    optionBoxSelected: {
      backgroundColor: "#007BFF",
      color: "white",
      borderColor: "#007BFF",
    },
    hiddenInput: {
      display: "none",
    },
    sectionTitleBox: {
      padding: "10px",
      marginBottom: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      backgroundColor: "#e9ecef",
      textAlign: "center",
      fontSize: "18px",
      fontWeight: "bold",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      marginBottom: "20px",
      fontSize: "14px",
    },
    th: {
      padding: "12px",
      textAlign: "center",
      color: "black",
      borderBottom: "1px solid #ddd",
      backgroundColor: "white",
    },
    td: {
      padding: "8px",
      textAlign: "center",
      borderBottom: "1px solid #ddd",
      borderLeft: "none",
      borderRight: "none",
    },
    scoreInput: {
      width: "40px",
      padding: "5px",
      margin: "0 2px",
      border: "1px solid #4CAF50",
      borderRadius: "4px",
      fontSize: "14px",
    },
    confirmButton: {
      backgroundColor: "#4CAF50",
      color: "white",
      border: "none",
      padding: "6px 12px",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "14px",
    },
    cancelButton: {
      backgroundColor: "#f44336",
      color: "white",
      border: "none",
      padding: "6px 12px",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "14px",
    },
    weekHeader: {
      backgroundColor: "#063970",
      color: "white",
      padding: "8px",
      marginBottom: "0",
      fontSize: "16px",
      fontWeight: "bold",
    },
    tournamentHeader: {
      backgroundColor: "#f2f2f2",
      padding: "12px",
      marginBottom: "10px",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%", // Genişlik %100 olarak ayarlandı
      boxSizing: "border-box",
      border: "1px solid #ccc",
      height: "100px",
    },
    tournamentTitle: {
      margin: 0,
      fontSize: "20px",
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
    },
    trophyIcon: {
      fontSize: "24px",
      marginLeft: "10px",
    },
    systemContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      boxSizing: "border-box",
    },
    systemBox: {
      width: "100%", // Genişlik %100 olarak ayarlandı
      padding: "20px",
      border: "1px solid #ccc",
      borderRadius: "8px",
      textAlign: "center",
      cursor: "pointer",
      transition: "background-color 0.3s",
      marginBottom: "10px",
      boxSizing: "border-box",
      height: "100px",
    },
    systemBoxHover: {
      backgroundColor: "#f0f0f0",
    },
  };
  const tableHeaderStyles = {
    s: { ...styles.th, backgroundColor: "#808080", width: "5%" },
    team: { ...styles.th, backgroundColor: "#008CBA", width: "25%" },
    ogbm: { ...styles.th, backgroundColor: "#4CAF50", width: "7%" },
    a: { ...styles.th, backgroundColor: "#FFD700", width: "7%" },
    y: { ...styles.th, backgroundColor: "#FF4136", width: "7%" },
    av: { ...styles.th, backgroundColor: "#FF851B", width: "7%" },
    p: { ...styles.th, backgroundColor: "#0074D9", width: "7%" },
  };

  const mobileStyles = {
    container: {
      width: "98%",
      padding: "5px",
      margin: "0 auto",
    },
    table: {
      fontSize: "12px",
    },
    th: {
      padding: "8px",
    },
    td: {
      padding: "6px",
    },
    scoreInput: {
      width: "30px",
      padding: "4px",
      fontSize: "12px",
    },
    confirmButton: {
      padding: "4px 8px",
      fontSize: "12px",
      backgroundColor: "#4CAF50",
      color: "white",
    },
    cancelButton: {
      padding: "4px 8px",
      fontSize: "12px",
      backgroundColor: "#f44336",
      color: "white",
    },
    awayTeam: {
      textAlign: "right",
    },
    actionButton: {
      display: "block",
      margin: "0 auto",
      border: "none",
    },
    weekHeader: {
      ...styles.weekHeader,
      fontSize: "14px",
      padding: "6px",
    },
    tournamentHeader: {
      ...styles.tournamentHeader,
      padding: "8px",
    },
    tournamentTitle: {
      ...styles.tournamentTitle,
      fontSize: "18px",
    },
    trophyIcon: {
      ...styles.trophyIcon,
      fontSize: "20px",
    },
  };

  const isMobile = window.innerWidth <= 600;
  const finalStyles = isMobile ? { ...styles, ...mobileStyles } : styles;

  return (
    <div style={finalStyles.container}>
      {showConfirmation && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <h2>Sonlandırılsın Mı?</h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button
                onClick={confirmEndTournament}
                style={{
                  backgroundColor: "#4CAF50",
                  color: "white",
                  border: "none",
                  padding: "10px 20px",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
              >
                Evet
              </button>
              <button
                onClick={() => setShowConfirmation(false)}
                style={{
                  backgroundColor: "#f44336",
                  color: "white",
                  border: "none",
                  padding: "10px 20px",
                  cursor: "pointer",
                }}
              >
                Hayır
              </button>
            </div>
          </div>
        </div>
      )}
      {step === 1 && (
        <>
          <div
            style={{
              ...finalStyles.tournamentHeader,
              backgroundColor: "#f2f2f2",
              border: "1px solid #ccc",
              marginBottom: "20px",
              textAlign: "center",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <h1
              style={{
                ...finalStyles.tournamentTitle,
                justifyContent: "center",
              }}
            >
              Turnuva Sistemi Seçin
              <span
                style={finalStyles.trophyIcon}
                role="img"
                aria-label="trophy"
              >
                🏆
              </span>
            </h1>
          </div>
          <div
            style={{
              ...styles.systemContainer,
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              style={styles.systemBox}
              onClick={() => handleSystemSelect("league")}
            >
              <span role="img" aria-label="league">
                ⚽
              </span>
              <p>Lig</p>
            </div>
            <div
              style={styles.systemBox}
              onClick={() => handleSystemSelect("groups")}
            >
              <span role="img" aria-label="groups">
                👥
              </span>
              <p>Gruplar</p>
            </div>
            <div
              style={styles.systemBox}
              onClick={() => handleSystemSelect("elimination")}
            >
              <span role="img" aria-label="elimination">
                🏅🥈🥉
              </span>
              <p>Eleme Sistemi</p>
            </div>
            <div
              style={styles.systemBox}
              onClick={() => handleSystemSelect("challenge")}
            >
              <span role="img" aria-label="challenge">
                💪
              </span>
              <p>Meydan Okuma</p>
            </div>
          </div>
        </>
      )}
      {step === 2 && (
        <>
          <h1>Turnuva İsmi</h1>
          <input
            type="text"
            value={tournamentName}
            onChange={(e) => setTournamentName(e.target.value)}
            placeholder="Turnuva İsmi"
            style={styles.input}
          />
          <button
            onClick={handleTournamentNameSubmit}
            style={finalStyles.button}
          >
            Devam Et
          </button>
        </>
      )}
      {step === 3 && (
        <>
          <h1>Katılımcı Sayısı</h1>
          <input
            type="number"
            value={participantCount}
            onChange={(e) => setParticipantCount(e.target.value)}
            placeholder="Katılımcı Sayısı"
            style={styles.input}
          />
          <button
            onClick={handleParticipantCountSubmit}
            style={finalStyles.button}
          >
            Devam Et
          </button>
        </>
      )}
      {step === 4 && (
        <>
          <h1>Katılımcı İsimleri</h1>
          {participants.map((participant, index) => (
            <input
              key={index}
              type="text"
              value={participant}
              onChange={(e) =>
                handleParticipantNameChange(index, e.target.value)
              }
              placeholder={`Katılımcı ${index + 1}`}
              style={styles.input}
            />
          ))}
          <button onClick={handleParticipantsSubmit} style={finalStyles.button}>
            Devam Et
          </button>
        </>
      )}
      {step === 5 && (
        <>
          <div style={styles.header}>Maç Türü</div>
          <div style={styles.optionContainer}>
            <label
              style={{
                ...styles.optionBox,
                ...(matchType === "single" ? styles.optionBoxSelected : {}),
              }}
              onClick={() => setMatchType("single")}
            >
              <input
                type="radio"
                value="single"
                checked={matchType === "single"}
                onChange={() => setMatchType("single")}
                style={styles.hiddenInput}
              />
              Tek Maç
            </label>
            <label
              style={{
                ...styles.optionBox,
                ...(matchType === "double" ? styles.optionBoxSelected : {}),
              }}
              onClick={() => setMatchType("double")}
            >
              <input
                type="radio"
                value="double"
                checked={matchType === "double"}
                onChange={() => setMatchType("double")}
                style={styles.hiddenInput}
              />
              Çift Maç
            </label>
          </div>
          <button onClick={handleMatchTypeSubmit} style={styles.button}>
            Fikstür Oluştur
          </button>
        </>
      )}

      {step === 6 && (
        <>
          <div
            style={{
              ...finalStyles.tournamentHeader,
              backgroundColor: "#f2f2f2",
              border: "1px solid #ccc",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            <h1 style={finalStyles.tournamentTitle}>
              {tournamentName} Tur. Fikstürü
              <span
                style={finalStyles.trophyIcon}
                role="img"
                aria-label="trophy"
              >
                🏆
              </span>
            </h1>
          </div>
          {fixtures.map((round, roundIndex) => (
            <div
              key={round.round}
              style={{ marginBottom: "20px", border: "1px solid #ddd" }}
            >
              <h2 style={{ ...finalStyles.weekHeader, margin: "0" }}>
                {round.round}. Hafta
              </h2>
              <table
                style={{
                  ...finalStyles.table,
                  border: "none",
                  marginTop: "0",
                  width: "100%",
                  tableLayout: "fixed",
                }}
              >
                <thead>
                  <tr>
                    {["Ev Sahibi", "Skor", "Deplasman", "Durum"].map(
                      (header, index) => (
                        <th
                          key={index}
                          style={{
                            ...finalStyles.th,
                            width: index === 1 || index === 3 ? "20%" : "30%",
                            borderRight: "none",
                            borderLeft: "none",
                            minWidth: index === 1 ? "120px" : "100px",
                          }}
                        >
                          {header}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {round.matches.map((match, matchIndex) => {
                    const key = `$${roundIndex}-$${matchIndex}`;
                    const tempScore = tempScores[key] || {};
                    return (
                      <tr
                        key={matchIndex}
                        style={{
                          backgroundColor:
                            matchIndex % 2 === 0 ? "#f2f2f2" : "white",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <td style={{ ...finalStyles.td, minWidth: "100px" }}>
                          {match.home}
                        </td>
                        <td style={{ ...finalStyles.td, minWidth: "120px" }}>
                          {match.isCompleted ? (
                            <span
                              style={{
                                display: "inline-block",
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              {match.homeScore}:{match.awayScore}
                            </span>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="number"
                                value={
                                  tempScore.homeScore !== undefined
                                    ? tempScore.homeScore
                                    : ""
                                }
                                onChange={(e) =>
                                  updateTempScore(
                                    roundIndex,
                                    matchIndex,
                                    Number(e.target.value),
                                    tempScore.awayScore
                                  )
                                }
                                style={{
                                  ...finalStyles.scoreInput,
                                  width: "40%",
                                }}
                              />
                              <span style={{ margin: "0 5px" }}>:</span>
                              <input
                                type="number"
                                value={
                                  tempScore.awayScore !== undefined
                                    ? tempScore.awayScore
                                    : ""
                                }
                                onChange={(e) =>
                                  updateTempScore(
                                    roundIndex,
                                    matchIndex,
                                    tempScore.homeScore,
                                    Number(e.target.value)
                                  )
                                }
                                style={{
                                  ...finalStyles.scoreInput,
                                  width: "40%",
                                }}
                              />
                            </div>
                          )}
                        </td>
                        <td
                          style={{
                            ...finalStyles.td,
                            ...finalStyles.awayTeam,
                            minWidth: "100px",
                          }}
                        >
                          {match.away}
                        </td>
                        <td style={{ ...finalStyles.td, minWidth: "100px" }}>
                          {match.isCompleted ? (
                            <button
                              onClick={() => editScore(roundIndex, matchIndex)}
                              style={{
                                ...finalStyles.cancelButton,
                                ...finalStyles.actionButton,
                              }}
                            >
                              İptal Et
                            </button>
                          ) : (
                            <button
                              onClick={() =>
                                confirmScore(roundIndex, matchIndex)
                              }
                              style={{
                                ...finalStyles.confirmButton,
                                ...finalStyles.actionButton,
                              }}
                            >
                              Onay
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ))}

          <h1>Puan Durumu</h1>
          <table style={finalStyles.table}>
            <thead>
              <tr>
                <th style={tableHeaderStyles.s}>S</th>
                <th style={tableHeaderStyles.team}>Takım</th>
                <th style={tableHeaderStyles.ogbm}>O</th>
                <th style={tableHeaderStyles.ogbm}>G</th>
                <th style={tableHeaderStyles.ogbm}>B</th>
                <th style={tableHeaderStyles.ogbm}>M</th>
                <th style={tableHeaderStyles.a}>A</th>
                <th style={tableHeaderStyles.y}>Y</th>
                <th style={tableHeaderStyles.av}>Av.</th>
                <th style={tableHeaderStyles.p}>P</th>
              </tr>
            </thead>
            <tbody>
              {leagueTable.map((team, index) => (
                <tr
                  key={team.name}
                  style={index % 2 === 0 ? { backgroundColor: "#f2f2f2" } : {}}
                >
                  <td style={finalStyles.td}>{index + 1}</td>
                  <td style={finalStyles.td}>{team.name}</td>
                  <td style={finalStyles.td}>{team.played}</td>
                  <td style={finalStyles.td}>{team.won}</td>
                  <td style={finalStyles.td}>{team.drawn}</td>
                  <td style={finalStyles.td}>{team.lost}</td>
                  <td style={finalStyles.td}>{team.goalsFor}</td>
                  <td style={finalStyles.td}>{team.goalsAgainst}</td>
                  <td style={finalStyles.td}>{team.goalDifference}</td>
                  <td style={finalStyles.td}>{team.points}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {champion && (
            <div style={finalStyles.championText}>
              TEBRİKLER! ŞAMPİYON {champion} 🏆
            </div>
          )}
          <button
            onClick={endTournament}
            style={{ ...finalStyles.button, backgroundColor: "#f44336" }}
          >
            Turnuvayı Bitir
          </button>
        </>
      )}
      {step === 8 && (
        <>
          <h1>Turnuva İsmi</h1>
          <input
            type="text"
            value={tournamentName}
            onChange={(e) => setTournamentName(e.target.value)}
            placeholder="Turnuva İsmi"
            style={styles.input}
          />
          <h1>Katılımcı Sayısı</h1>
          <select
            value={participantCount}
            onChange={(e) => setParticipantCount(e.target.value)}
            style={styles.input}
          >
            <option value="">Seçiniz</option>
            {[4, 8, 16, 32, 64].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
          <button
            onClick={handleEliminationParticipantCountSubmit}
            style={finalStyles.button}
          >
            Devam Et
          </button>
        </>
      )}

      {step === 9 && (
        <>
          <h1>Katılımcı İsimleri</h1>
          {eliminationParticipants.map((participant, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <input
                type="text"
                value={participant.name}
                onChange={(e) =>
                  handleEliminationParticipantNameChange(
                    index,
                    e.target.value,
                    participant.isSeeded
                  )
                }
                placeholder={`Katılımcı ${index + 1}`}
                style={{ ...styles.input, flex: 1, marginRight: "10px" }}
              />
              <label>
                Seri Başı
                <input
                  type="checkbox"
                  checked={participant.isSeeded}
                  onChange={(e) =>
                    handleEliminationParticipantNameChange(
                      index,
                      participant.name,
                      e.target.checked
                    )
                  }
                />
              </label>
            </div>
          ))}
          <button
            onClick={startEliminationTournament}
            style={finalStyles.button}
          >
            Turnuvayı Başlat
          </button>
        </>
      )}

      {step === 10 && (
        <>
          <div style={finalStyles.tournamentHeader}>
            <h1 style={finalStyles.tournamentTitle}>
              {tournamentName}
              <span
                style={finalStyles.trophyIcon}
                role="img"
                aria-label="trophy"
              >
                🏆
              </span>
            </h1>
          </div>
          {eliminationFixtures.map((match, index) => (
            <div
              key={index}
              style={{ marginBottom: "20px", border: "1px solid #ddd" }}
            >
              <h2 style={{ ...finalStyles.weekHeader, margin: "0" }}>
                {eliminationRound === 3
                  ? `Çeyrek Final Turu ${index + 1}. Maç`
                  : eliminationRound === 2
                  ? `Yarı Final Turu ${index + 1}. Maç`
                  : eliminationRound === 1
                  ? `${tournamentName} Final Maçı`
                  : `${eliminationRound}. Etap Mücadelesi ${index + 1}. Maç`}
              </h2>
              <table
                style={{
                  ...finalStyles.table,
                  border: "none",
                  marginTop: "0",
                  width: "100%",
                  tableLayout: "fixed",
                }}
              >
                <thead>
                  <tr>
                    {["Ev Sahibi", "Skor", "Deplasman", "Durum"].map(
                      (header, i) => (
                        <th
                          key={i}
                          style={{
                            ...finalStyles.th,
                            width: i === 1 || i === 3 ? "20%" : "30%",
                            borderRight: "none",
                            borderLeft: "none",
                            minWidth: i === 1 ? "120px" : "100px",
                          }}
                        >
                          {header}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    <td style={{ ...finalStyles.td, minWidth: "100px" }}>
                      {match.home.name}
                    </td>
                    <td style={{ ...finalStyles.td, minWidth: "120px" }}>
                      {match.isCompleted ? (
                        <span
                          style={{
                            display: "inline-block",
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          {match.homeScore}:{match.awayScore}
                        </span>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="number"
                            value={
                              match.homeScore !== null ? match.homeScore : ""
                            }
                            onChange={(e) =>
                              updateEliminationScore(
                                index,
                                "home",
                                Number(e.target.value)
                              )
                            }
                            style={{ ...finalStyles.scoreInput, width: "40%" }}
                          />
                          <span style={{ margin: "0 5px" }}>:</span>
                          <input
                            type="number"
                            value={
                              match.awayScore !== null ? match.awayScore : ""
                            }
                            onChange={(e) =>
                              updateEliminationScore(
                                index,
                                "away",
                                Number(e.target.value)
                              )
                            }
                            style={{ ...finalStyles.scoreInput, width: "40%" }}
                          />
                        </div>
                      )}
                    </td>
                    <td
                      style={{
                        ...finalStyles.td,
                        ...finalStyles.awayTeam,
                        minWidth: "100px",
                      }}
                    >
                      {match.away.name}
                    </td>
                    <td style={{ ...finalStyles.td, minWidth: "100px" }}>
                      {match.isCompleted ? (
                        <button
                          onClick={() => editEliminationScore(index)}
                          style={{
                            ...finalStyles.cancelButton,
                            ...finalStyles.actionButton,
                          }}
                        >
                          İptal Et
                        </button>
                      ) : (
                        <button
                          onClick={() => confirmEliminationScore(index)}
                          style={{
                            ...finalStyles.confirmButton,
                            ...finalStyles.actionButton,
                          }}
                        >
                          Onay
                        </button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
          {eliminationWinner && (
            <div style={{ ...finalStyles.championText, marginTop: "20px" }}>
              {tournamentName} Şampiyonu: {eliminationWinner.name} 🏆
            </div>
          )}
          <button
            onClick={endTournament}
            style={{ ...finalStyles.button, backgroundColor: "#f44336" }}
          >
            Turnuvayı Bitir
          </button>
        </>
      )}
      {step === 11 && (
        <>
          <div style={styles.header}>Turnuva Oluştur</div>

          <div style={styles.formGroup}>
            <label style={styles.label}>Turnuva İsmi</label>
            <input
              type="text"
              value={tournamentName}
              onChange={(e) => setTournamentName(e.target.value)}
              placeholder="Turnuva İsmi"
              style={styles.input}
            />
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>Katılımcı Sayısı</label>
            <input
              type="number"
              value={participantCount}
              onChange={(e) => {
                setParticipantCount(e.target.value);
                setGroupParticipants(
                  Array(parseInt(e.target.value)).fill({
                    name: "",
                    isSeeded: false,
                  })
                );
              }}
              placeholder="Katılımcı Sayısı"
              style={styles.input}
            />
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>Katılımcı İsimleri</label>
            {groupParticipants.map((participant, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="text"
                  value={participant.name}
                  onChange={(e) =>
                    handleGroupParticipantChange(index, "name", e.target.value)
                  }
                  placeholder={`Katılımcı ${index + 1}`}
                  style={{ ...styles.input, flex: 1, marginRight: "10px" }}
                />
                <label>
                  Seri Başı
                  <input
                    type="checkbox"
                    checked={participant.isSeeded}
                    onChange={(e) =>
                      handleGroupParticipantChange(
                        index,
                        "isSeeded",
                        e.target.checked
                      )
                    }
                  />
                </label>
              </div>
            ))}
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>Grup Sayısı</label>
            <select
              value={groupCount}
              onChange={(e) => setGroupCount(e.target.value)}
              style={styles.input}
            >
              <option value="">Seçiniz</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
            </select>
          </div>

          <button onClick={handleGroupsSubmit} style={styles.button}>
            Grupları Oluştur
          </button>
        </>
      )}

      {step === 12 && (
        <>
          <div style={finalStyles.tournamentHeader}>
            <h1 style={finalStyles.tournamentTitle}>
              {tournamentName} Grupları
              <span
                style={finalStyles.trophyIcon}
                role="img"
                aria-label="trophy"
              >
                🏆
              </span>
            </h1>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
            }}
          >
            {formattedGroups.map((group, index) => (
              <div
                key={index}
                style={{ margin: "10px", minWidth: "200px", flex: "1" }}
              >
                <h2 style={{ ...finalStyles.weekHeader, textAlign: "center" }}>
                  Grup {String.fromCharCode(65 + index)}
                </h2>
                <table style={{ ...finalStyles.table, width: "100%" }}>
                  <thead>
                    <tr>
                      <th style={tableHeaderStyles.team}>Takım</th>
                      <th style={tableHeaderStyles.s}>Seri Başı</th>
                    </tr>
                  </thead>
                  <tbody>
                    {group.map((participant, pIndex) => (
                      <tr
                        key={pIndex}
                        style={
                          pIndex % 2 === 0 ? { backgroundColor: "#f2f2f2" } : {}
                        }
                      >
                        <td style={finalStyles.td}>{participant.name}</td>
                        <td style={finalStyles.td}>
                          {participant.isSeeded ? "✅" : "❌"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>

          <div style={styles.sectionTitleBox}>Maç Sayısı</div>
          <div style={styles.optionContainer}>
            <label
              style={{
                ...styles.optionBox,
                ...(matchCount === "single" ? styles.optionBoxSelected : {}),
              }}
              onClick={() => setMatchCount("single")}
            >
              <input
                type="radio"
                value="single"
                checked={matchCount === "single"}
                onChange={() => setMatchCount("single")}
                style={styles.hiddenInput}
              />
              Tek Maç
            </label>
            <label
              style={{
                ...styles.optionBox,
                ...(matchCount === "double" ? styles.optionBoxSelected : {}),
              }}
              onClick={() => setMatchCount("double")}
            >
              <input
                type="radio"
                value="double"
                checked={matchCount === "double"}
                onChange={() => setMatchCount("double")}
                style={styles.hiddenInput}
              />
              Çift Maç
            </label>
          </div>

          <div style={styles.sectionTitleBox}>Gruptan Çıkacak Takım Sayısı</div>
          <div style={styles.optionContainer}>
            <label
              style={{
                ...styles.optionBox,
                ...(teamsAdvancing === "2" ? styles.optionBoxSelected : {}),
              }}
              onClick={() => setTeamsAdvancing("2")}
            >
              <input
                type="radio"
                value="2"
                checked={teamsAdvancing === "2"}
                onChange={() => setTeamsAdvancing("2")}
                style={styles.hiddenInput}
              />
              2 Takım
            </label>
            <label
              style={{
                ...styles.optionBox,
                ...(teamsAdvancing === "3" ? styles.optionBoxSelected : {}),
              }}
              onClick={() => setTeamsAdvancing("3")}
            >
              <input
                type="radio"
                value="3"
                checked={teamsAdvancing === "3"}
                onChange={() => setTeamsAdvancing("3")}
                style={styles.hiddenInput}
              />
              3 Takım
            </label>
            <label
              style={{
                ...styles.optionBox,
                ...(teamsAdvancing === "4" ? styles.optionBoxSelected : {}),
              }}
              onClick={() => setTeamsAdvancing("4")}
            >
              <input
                type="radio"
                value="4"
                checked={teamsAdvancing === "4"}
                onChange={() => setTeamsAdvancing("4")}
                style={styles.hiddenInput}
              />
              4 Takım
            </label>
          </div>

          <button
            onClick={() => setStep(13)}
            style={{ ...styles.button, marginTop: "20px" }}
            disabled={!matchCount || !teamsAdvancing}
          >
            Fikstür Oluştur
          </button>
        </>
      )}
      {step === 13 && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            {formattedGroups.map((group, index) => (
              <button
                key={index}
                onClick={() => setSelectedGroup(index)}
                style={{
                  ...finalStyles.button,
                  backgroundColor:
                    selectedGroup === index ? "#4CAF50" : "#f2f2f2",
                  color: selectedGroup === index ? "white" : "black",
                  marginRight: "10px",
                  border:
                    selectedGroup === index
                      ? "1px solid #4CAF50"
                      : "1px solid #ccc",
                }}
              >
                Grup {String.fromCharCode(65 + index)}
              </button>
            ))}
          </div>

          <div style={finalStyles.tournamentHeader}>
            <h1 style={finalStyles.tournamentTitle}>
              {tournamentName} Grup {String.fromCharCode(65 + selectedGroup)}{" "}
              Fikstürü
              <span
                style={finalStyles.trophyIcon}
                role="img"
                aria-label="trophy"
              >
                🏆
              </span>
            </h1>
          </div>

          {groupFixtures[selectedGroup] &&
            groupFixtures[selectedGroup].map((week, weekIndex) => (
              <div
                key={weekIndex}
                style={{ marginBottom: "20px", border: "1px solid #ddd" }}
              >
                <h3 style={{ ...finalStyles.weekHeader, margin: "0" }}>
                  {week.week}. Hafta
                </h3>
                <table
                  style={{
                    ...finalStyles.table,
                    border: "none",
                    marginTop: "0",
                    width: "100%",
                    tableLayout: "fixed",
                  }}
                >
                  <thead>
                    <tr>
                      {["Ev Sahibi", "Skor", "Deplasman", "Durum"].map(
                        (header, index) => (
                          <th
                            key={index}
                            style={{
                              ...finalStyles.th,
                              width: index === 1 || index === 3 ? "20%" : "30%",
                              borderRight: "none",
                              borderLeft: "none",
                              minWidth: index === 1 ? "120px" : "100px",
                            }}
                          >
                            {header}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {week.matches.map((match, matchIndex) => (
                      <tr
                        key={matchIndex}
                        style={{
                          backgroundColor: "#f2f2f2",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <td style={{ ...finalStyles.td, minWidth: "100px" }}>
                          {match.home.name}
                        </td>
                        <td style={{ ...finalStyles.td, minWidth: "120px" }}>
                          {match.isConfirmed ? (
                            <span
                              style={{
                                display: "inline-block",
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              {match.homeScore}:{match.awayScore}
                            </span>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="number"
                                value={match.homeScore || ""}
                                onChange={(e) =>
                                  updateGroupScore(
                                    selectedGroup,
                                    weekIndex,
                                    matchIndex,
                                    "home",
                                    e.target.value
                                  )
                                }
                                style={{
                                  ...finalStyles.scoreInput,
                                  width: "40%",
                                }}
                              />
                              <span style={{ margin: "0 5px" }}>:</span>
                              <input
                                type="number"
                                value={match.awayScore || ""}
                                onChange={(e) =>
                                  updateGroupScore(
                                    selectedGroup,
                                    weekIndex,
                                    matchIndex,
                                    "away",
                                    e.target.value
                                  )
                                }
                                style={{
                                  ...finalStyles.scoreInput,
                                  width: "40%",
                                }}
                              />
                            </div>
                          )}
                        </td>
                        <td
                          style={{
                            ...finalStyles.td,
                            ...finalStyles.awayTeam,
                            minWidth: "100px",
                          }}
                        >
                          {match.away.name}
                        </td>
                        <td style={{ ...finalStyles.td, minWidth: "100px" }}>
                          {match.isConfirmed ? (
                            <button
                              onClick={() =>
                                editGroupScore(
                                  selectedGroup,
                                  weekIndex,
                                  matchIndex
                                )
                              }
                              style={{
                                ...finalStyles.cancelButton,
                                ...finalStyles.actionButton,
                              }}
                            >
                              İptal Et
                            </button>
                          ) : (
                            <button
                              onClick={() =>
                                confirmGroupScore(
                                  selectedGroup,
                                  weekIndex,
                                  matchIndex
                                )
                              }
                              style={{
                                ...finalStyles.confirmButton,
                                ...finalStyles.actionButton,
                              }}
                            >
                              Onay
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          <h3>Puan Durumu</h3>
          <table style={finalStyles.table}>
            <thead>
              <tr>
                <th style={tableHeaderStyles.s}>S</th>
                <th style={tableHeaderStyles.team}>Takım</th>
                <th style={tableHeaderStyles.ogbm}>O</th>
                <th style={tableHeaderStyles.ogbm}>G</th>
                <th style={tableHeaderStyles.ogbm}>B</th>
                <th style={tableHeaderStyles.ogbm}>M</th>
                <th style={tableHeaderStyles.a}>A</th>
                <th style={tableHeaderStyles.y}>Y</th>
                <th style={tableHeaderStyles.av}>Av.</th>
                <th style={tableHeaderStyles.p}>P</th>
              </tr>
            </thead>
            <tbody>
              {groupStandings[selectedGroup] &&
                groupStandings[selectedGroup].map((team, teamIndex) => (
                  <tr
                    key={teamIndex}
                    style={
                      teamIndex % 2 === 0 ? { backgroundColor: "#f2f2f2" } : {}
                    }
                  >
                    <td style={finalStyles.td}>{teamIndex + 1}</td>
                    <td style={finalStyles.td}>{team.name}</td>
                    <td style={finalStyles.td}>{team.played}</td>
                    <td style={finalStyles.td}>{team.won}</td>
                    <td style={finalStyles.td}>{team.drawn}</td>
                    <td style={finalStyles.td}>{team.lost}</td>
                    <td style={finalStyles.td}>{team.goalsFor}</td>
                    <td style={finalStyles.td}>{team.goalsAgainst}</td>
                    <td style={finalStyles.td}>{team.goalDifference}</td>
                    <td style={finalStyles.td}>{team.points}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      )}

      {step === 14 && (
        <>
          <h2>{tournamentName} Eşleşmeleri</h2>

          {/* Sonlanmış Grupların Puan Tabloları */}
          {Object.keys(groupStandings).map((groupIndex) => (
            <div
              key={groupIndex}
              style={{ marginBottom: "20px", border: "1px solid #ddd" }}
            >
              <h3 style={{ ...finalStyles.weekHeader, margin: "0" }}>
                Grup {String.fromCharCode(65 + parseInt(groupIndex))} Puan
                Durumu
              </h3>
              <table style={finalStyles.table}>
                <thead>
                  <tr>
                    <th style={tableHeaderStyles.s}>S</th>
                    <th style={tableHeaderStyles.team}>Takım</th>
                    <th style={tableHeaderStyles.ogbm}>O</th>
                    <th style={tableHeaderStyles.ogbm}>G</th>
                    <th style={tableHeaderStyles.ogbm}>B</th>
                    <th style={tableHeaderStyles.ogbm}>M</th>
                    <th style={tableHeaderStyles.a}>A</th>
                    <th style={tableHeaderStyles.y}>Y</th>
                    <th style={tableHeaderStyles.av}>Av.</th>
                    <th style={tableHeaderStyles.p}>P</th>
                  </tr>
                </thead>
                <tbody>
                  {groupStandings[groupIndex].map((team, teamIndex) => (
                    <tr
                      key={teamIndex}
                      style={
                        teamIndex % 2 === 0
                          ? { backgroundColor: "#f2f2f2" }
                          : {}
                      }
                    >
                      <td style={finalStyles.td}>{teamIndex + 1}</td>
                      <td style={finalStyles.td}>{team.name}</td>
                      <td style={finalStyles.td}>{team.played}</td>
                      <td style={finalStyles.td}>{team.won}</td>
                      <td style={finalStyles.td}>{team.drawn}</td>
                      <td style={finalStyles.td}>{team.lost}</td>
                      <td style={finalStyles.td}>{team.goalsFor}</td>
                      <td style={finalStyles.td}>{team.goalsAgainst}</td>
                      <td style={finalStyles.td}>{team.goalDifference}</td>
                      <td style={finalStyles.td}>{team.points}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}

          {knockoutFixtures.map((match, index) => (
            <div
              key={index}
              style={{ marginBottom: "20px", border: "1px solid #ddd" }}
            >
              <h3 style={{ ...finalStyles.weekHeader, margin: "0" }}>
                {`${tournamentName} ${getRoundName(knockoutFixtures.length)} ${
                  index + 1
                }. Maç`}
              </h3>
              <table
                style={{
                  ...finalStyles.table,
                  border: "none",
                  marginTop: "0",
                  width: "100%",
                  tableLayout: "fixed",
                }}
              >
                <thead>
                  <tr>
                    {["Ev Sahibi", "Skor", "Deplasman", "Durum"].map(
                      (header, index) => (
                        <th
                          key={index}
                          style={{
                            ...finalStyles.th,
                            width: index === 1 || index === 3 ? "20%" : "30%",
                            borderRight: "none",
                            borderLeft: "none",
                            minWidth: index === 1 ? "120px" : "100px",
                          }}
                        >
                          {header}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    <td style={{ ...finalStyles.td, minWidth: "100px" }}>
                      {match.home.name}
                    </td>
                    <td style={{ ...finalStyles.td, minWidth: "120px" }}>
                      {match.isConfirmed ? (
                        <span
                          style={{
                            display: "inline-block",
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          {match.homeScore}:{match.awayScore}
                        </span>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="number"
                            value={match.homeScore || ""}
                            onChange={(e) =>
                              updateKnockoutScore(index, "home", e.target.value)
                            }
                            style={{
                              ...finalStyles.scoreInput,
                              width: "40%",
                            }}
                          />
                          <span style={{ margin: "0 5px" }}>:</span>
                          <input
                            type="number"
                            value={match.awayScore || ""}
                            onChange={(e) =>
                              updateKnockoutScore(index, "away", e.target.value)
                            }
                            style={{
                              ...finalStyles.scoreInput,
                              width: "40%",
                            }}
                          />
                        </div>
                      )}
                    </td>
                    <td
                      style={{
                        ...finalStyles.td,
                        ...finalStyles.awayTeam,
                        minWidth: "100px",
                      }}
                    >
                      {match.away.name}
                    </td>
                    <td style={{ ...finalStyles.td, minWidth: "100px" }}>
                      {match.isConfirmed ? (
                        <button
                          onClick={() => editKnockoutScore(index)}
                          style={{
                            ...finalStyles.cancelButton,
                            ...finalStyles.actionButton,
                          }}
                        >
                          İptal Et
                        </button>
                      ) : (
                        <button
                          onClick={() => confirmKnockoutScore(index)}
                          style={{
                            ...finalStyles.confirmButton,
                            ...finalStyles.actionButton,
                          }}
                        >
                          Onay
                        </button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
          {champion ? (
            <>
              <div style={finalStyles.championText}>
                {tournamentName} Şampiyonu {champion}
              </div>
              <button
                onClick={endTournament}
                style={{ ...finalStyles.button, backgroundColor: "#f44336" }}
              >
                Turnuvayı Bitir
              </button>
            </>
          ) : (
            <button
              onClick={endTournament}
              style={{ ...finalStyles.button, backgroundColor: "#f44336" }}
            >
              Turnuvayı Bitir
            </button>
          )}
        </>
      )}

      {step === 7 && (
        <>
          {challengeStep === 1 && (
            <>
              <div
                style={{
                  ...finalStyles.tournamentHeader,
                  backgroundColor: "#f2f2f2",
                  border: "1px solid #ccc",
                  marginBottom: "20px",
                  textAlign: "center",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <h1
                  style={{
                    ...finalStyles.tournamentTitle,
                    justifyContent: "center",
                  }}
                >
                  Gladyatör İsimleri
                  <span
                    style={finalStyles.trophyIcon}
                    role="img"
                    aria-label="trophy"
                  >
                    🏆
                  </span>
                </h1>
              </div>
              <input
                type="text"
                value={gladiator1}
                onChange={(e) => setGladiator1(e.target.value)}
                placeholder="Gladyatör 1"
                style={styles.input}
              />
              <input
                type="text"
                value={gladiator2}
                onChange={(e) => setGladiator2(e.target.value)}
                placeholder="Gladyatör 2"
                style={styles.input}
              />
              <div style={styles.optionContainer}>
                <label
                  style={{
                    ...styles.optionBox,
                    ...(bestOf === "3" ? styles.optionBoxSelected : {}),
                  }}
                  onClick={() => setBestOf("3")}
                >
                  <input
                    type="radio"
                    value="3"
                    checked={bestOf === "3"}
                    onChange={() => setBestOf("3")}
                    style={styles.hiddenInput}
                  />
                  Best Of 3 💪
                </label>
                <label
                  style={{
                    ...styles.optionBox,
                    ...(bestOf === "5" ? styles.optionBoxSelected : {}),
                  }}
                  onClick={() => setBestOf("5")}
                >
                  <input
                    type="radio"
                    value="5"
                    checked={bestOf === "5"}
                    onChange={() => setBestOf("5")}
                    style={styles.hiddenInput}
                  />
                  Best Of 5 💪
                </label>
              </div>
              <button
                onClick={() => {
                  if (gladiator1 && gladiator2 && bestOf) {
                    setChallengeStep(2);
                    generateChallengeFixtures();
                  }
                }}
                style={finalStyles.button}
              >
                Meydan Okumayı Başlat
              </button>
            </>
          )}
          {challengeStep === 2 && (
            <>
              <div
                style={{
                  ...finalStyles.tournamentHeader,
                  backgroundColor: "#f2f2f2",
                  border: "1px solid #ccc",
                  marginBottom: "20px",
                  textAlign: "center",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <h1
                  style={{
                    ...finalStyles.tournamentTitle,
                    justifyContent: "center",
                  }}
                >
                  {gladiator1} ⚔️ {gladiator2}
                </h1>
              </div>
              {challengeFixtures.map((match, index) => (
                <div
                  key={index}
                  style={{ marginBottom: "20px", border: "1px solid #ddd" }}
                >
                  <h2 style={{ ...finalStyles.weekHeader, margin: "0" }}>
                    {match.round}. Müsabaka ⚔️
                  </h2>
                  <table
                    style={{
                      ...finalStyles.table,
                      border: "none",
                      marginTop: "0",
                      width: "100%",
                      tableLayout: "fixed",
                    }}
                  >
                    <thead>
                      <tr>
                        {["Ev Sahibi", "Skor", "Deplasman", "Durum"].map(
                          (header, index) => (
                            <th
                              key={index}
                              style={{
                                ...finalStyles.th,
                                width:
                                  index === 1 || index === 3 ? "20%" : "30%",
                                borderRight: "none",
                                borderLeft: "none",
                                minWidth: index === 1 ? "120px" : "100px",
                              }}
                            >
                              {header}
                            </th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        style={{
                          backgroundColor: "#f2f2f2",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <td style={{ ...finalStyles.td, minWidth: "100px" }}>
                          {match.home}
                        </td>
                        <td style={{ ...finalStyles.td, minWidth: "120px" }}>
                          {match.isCompleted ? (
                            <span
                              style={{
                                display: "inline-block",
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              {match.homeScore}:{match.awayScore}
                            </span>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="number"
                                value={
                                  match.homeScore !== null
                                    ? match.homeScore
                                    : ""
                                }
                                onChange={(e) =>
                                  updateChallengeScore(
                                    index,
                                    "home",
                                    Number(e.target.value)
                                  )
                                }
                                style={{
                                  ...finalStyles.scoreInput,
                                  width: "40%",
                                }}
                                disabled={winner !== null}
                              />
                              <span style={{ margin: "0 5px" }}>:</span>
                              <input
                                type="number"
                                value={
                                  match.awayScore !== null
                                    ? match.awayScore
                                    : ""
                                }
                                onChange={(e) =>
                                  updateChallengeScore(
                                    index,
                                    "away",
                                    Number(e.target.value)
                                  )
                                }
                                style={{
                                  ...finalStyles.scoreInput,
                                  width: "40%",
                                }}
                                disabled={winner !== null}
                              />
                            </div>
                          )}
                        </td>
                        <td
                          style={{
                            ...finalStyles.td,
                            ...finalStyles.awayTeam,
                            minWidth: "100px",
                          }}
                        >
                          {match.away}
                        </td>
                        <td style={{ ...finalStyles.td, minWidth: "100px" }}>
                          {match.isCompleted ? (
                            <button
                              onClick={() => editChallengeScore(index)}
                              style={{
                                ...finalStyles.cancelButton,
                                ...finalStyles.actionButton,
                              }}
                              disabled={winner !== null}
                            >
                              İptal Et
                            </button>
                          ) : (
                            <button
                              onClick={() => confirmChallengeScore(index)}
                              style={{
                                ...finalStyles.confirmButton,
                                ...finalStyles.actionButton,
                              }}
                              disabled={winner !== null}
                            >
                              Onay
                            </button>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}

              <h1>Puan Durumu</h1>
              <table style={finalStyles.table}>
                <thead>
                  <tr>
                    <th style={tableHeaderStyles.team}>Gladyatör</th>
                    <th style={tableHeaderStyles.ogbm}>G</th>
                    <th style={tableHeaderStyles.ogbm}>M</th>
                    <th style={tableHeaderStyles.p}>P</th>
                  </tr>
                </thead>
                <tbody>
                  {challengeLeagueTable.map((gladiator, index) => (
                    <tr
                      key={gladiator.name}
                      style={
                        index % 2 === 0 ? { backgroundColor: "#f2f2f2" } : {}
                      }
                    >
                      <td style={finalStyles.td}>{gladiator.name}</td>
                      <td style={finalStyles.td}>{gladiator.won}</td>
                      <td style={finalStyles.td}>{gladiator.lost}</td>
                      <td style={finalStyles.td}>{gladiator.points}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {winner && (
                <div style={{ ...finalStyles.championText, marginTop: "20px" }}>
                  Kazanan: {winner} 💪⚔️
                </div>
              )}
              <button
                onClick={endTournament}
                style={{ ...finalStyles.button, backgroundColor: "#f44336" }}
              >
                Turnuvayı Bitir
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default App;
